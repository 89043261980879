import {
  HttpErrorResponse, HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest
} from '@angular/common/http';
import { inject } from '@angular/core';
import {
  catchError, tap, throwError, switchMap, from
} from 'rxjs';
import { AuthService } from './auth.service';
import { AlertService } from './alert-service.service';
import { LocalStorageService } from './local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { StorageKeys } from '../models/storage.interface';
import { TokenService } from './token.service';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  const isAuthenticated = inject(AuthService).isAuthorized();
  const alertService = inject(AlertService);
  const authService = inject(AuthService);
  const translation = inject(TranslateService);
  const router = inject(Router);
  const localStorage = inject(LocalStorageService);
  const tokenService = inject(TokenService);
  if (isAuthenticated && !req.url.includes('CMS/Resource/SetDocument')) {
    const authToken = localStorage.get('token');
    let authReq = checkIfRequestIsTranslateRequest(req, authToken, translation);

    return next(authReq).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 55555) { //TODO: change it with right status code when it's done from backend.
          // Token might be expired; try to refresh it
          return refreshToken(authService, localStorage, tokenService).pipe(
            switchMap(() => {
              // Retry the original request with the new token
              const newAuthToken = localStorage.get('token');
              authReq = checkIfRequestIsTranslateRequest(req, newAuthToken, translation);
              return next(authReq);
            }),
            catchError(refreshError => {
              // If refresh attempt fails, handle as standard 401
              handleServerErrors(err, localStorage, authService, router, alertService, translation);
              return throwError(() => refreshError);
            })
          );
        } else


        {
          // Handle other errors
          handleServerErrors(err, localStorage, authService, router, alertService, translation);
          return throwError(() => err);
        }
      })
    );
  } else if (req.url.includes('CMS/Resource/SetDocument')) {
    return next(req);
  } else {
    return handleUnAuthRequests(req, translation, next, localStorage, router, alertService, authService);
  }
};

// Token refresh method
function refreshToken(authService: AuthService, localStorage: LocalStorageService, tokenService: TokenService) {
  const token = localStorage.get('token');
  const refreshToken = localStorage.get('refreshToken');
  return from(authService.refreshToken(token, refreshToken)).pipe(
    tap((newToken: any) => {
      tokenService.getNewTokens(newToken);
    }),
    catchError(() => {
      // If refreshing the token fails, log the user out
      authService.logOut();
      return throwError(() => new Error('Token refresh failed'));
    })
  );
}

function checkIfRequestIsTranslateRequest(
  req: HttpRequest<any>,
  authToken: string,
  translation: TranslateService,
): HttpRequest<any> {
  return req.clone({
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + authToken,
      'Accept-Language': translation.currentLang || 'ar',
      "app-source": "unified-portal"
    }),
  });
}

function handleServerErrors(
  err: any,
  localStorage: LocalStorageService,
  authService: AuthService,
  router: Router,
  alertService: AlertService,
  translation: TranslateService,
) {
  if (err.status == 401) {
    localStorage.set(StorageKeys.redirectionURL, router.url);
    authService.logOut();
    // Specific handling for unauthorized errors
    // if(!authService.show401Toaster){
    //   alertService.alertError(
    //     translation.instant('general.errors.signError'),
    //     err?.error?.error?.message || err?.error?.error?.Message,
    //   );
    //   authService.show401Toaster = true;
    // }
  } else {
    // Handle other HTTP error codes
    alertService.alertError(
      translation.instant('general.errors.wrong'),
      err?.error?.error?.message ||
      err?.error?.error?.Message ||
      err?.error?.errors[0],
    );
  }
}

function handleClientErrors(
  err: any,
  alertService: AlertService,
  translation: TranslateService,
) {
  alertService.alertError(
    translation.instant('general.errors.wrong'),
    err?.error?.error?.message || err?.error?.error?.Message,
  );
}

function handleUnAuthRequests(
  req: HttpRequest<any>,
  translation: TranslateService,
  next: HttpHandlerFn,
  localStorage: LocalStorageService,
  router: Router,
  alertService: AlertService,
  authService: AuthService,
) {
  const unAuthRequest = req.clone({
    headers: new HttpHeaders({
      'Accept-Language': translation.currentLang || 'ar',
      "app-source": "unified-portal"
    }),
  });
  return next(unAuthRequest).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) {
          localStorage.set(StorageKeys.redirectionURL, router.url);
          authService.logOut();
          alertService.alertError('Unauthorized request', err.error.title);
        }
      }
      alertService.alertError(err.name as string, err.error.title);
      return throwError(() => err);
    }),
  );
}
